import request from '@/api/axios-request'

const apiAdmin = {

  create(username, password) {
    return request({
      url: '/ad/admins/create',
      method: 'post',
      data: {
        username,
        password
      }
    })
  },

  get(id) {
    return request({
      url: '/ad/admins/get',
      method: 'post',
      data: {
        id
      }
    })
  },

  update(id, password, status) {
    return request({
      url: '/ad/admins/update',
      method: 'post',
      data: {
        id,
        password,
        status
      }
    })
  },

  delete(id) {
    return request({
      url: '/ad/admins/delete',
      method: 'post',
      data: {
        id
      }
    })
  },

  list(page, limit, sortColumn = null, sortOrder = null, filters = {}) {
    return request({
      url: '/ad/admins/list',
      method: 'get',
      params: {
        page: page,
        limit: limit,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        filters: encodeURI(JSON.stringify(filters))
      }
    })
  }

}

export default apiAdmin