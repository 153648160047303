<template>
  <div>
    <CCard>
      <CCardHeader>Create New Admin User</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md='12' lg='6'>
            <CForm @submit='submitForm'>
              <CInput required placeholder='Username or Email' v-model='usernameInput.val' v-bind:class="{ valid: validateInput(usernameInput.val, 'username') }" :invalidFeedback="invalidFeedback('username')" :isValid="validateInput(usernameInput.val, 'username')">
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CInput required placeholder='Password' type='password' v-model='passwordInput.val' v-bind:class="{ valid: validateInput(passwordInput.val, 'password') }" :invalidFeedback="invalidFeedback('password')" :isValid="validateInput(passwordInput.val, 'password')">
                <template #prepend-content><CIcon name='cil-shield-alt'/></template>
              </CInput>
              <div class='form-group form-actions'>
                <CButton block type='submit' color='success' :disabled='formLoading'>
                  <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
                  Create
                </CButton>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import apiAdmin from '@/api/admin'

export default {
  name: 'CreateAdmin',
  data () {
    return {
      formLoading: false,
      usernameInput: { val: null, serverErrMsg: null , serverErrVal: null },
      passwordInput: { val: null, serverErrMsg: null , serverErrVal: null }
    }
  },
  methods: {
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'username'){ // 4 to 16 alphanumeric
          return (val && val.length >= 4 && val.length <= 16 && /^[A-Za-z0-9]+$/.test(val) && val !== this.usernameInput.serverErrVal) ? true : false
        }
        if(field == 'password'){ // 6 to 16 characters
          return (val && val.length >= 6 && val.length <= 16 && val !== this.passwordInput.serverErrVal) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'username'){
        return (this.usernameInput.val !== this.usernameInput.serverErrVal) ? '4 to 16 alphanumeric characters' : this.usernameInput.serverErrMsg
      }
      if(field == 'password'){
        return (this.passwordInput.val !== this.passwordInput.serverErrVal) ? '6 to 16 characters' : this.passwordInput.serverErrMsg
      }
    },
    submitForm: function (e) {

      e.preventDefault()

      if(this.validateInput(this.usernameInput.val, 'username') && this.validateInput(this.passwordInput.val, 'password')){
        
        this.formLoading = true

        apiAdmin.create(this.usernameInput.val, this.passwordInput.val).then(response => {
          this.formLoading = false
          this.$toast.success('Successfully created new admin.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'username'){
                this.usernameInput.serverErrVal = this.usernameInput.val
                this.usernameInput.serverErrMsg = error.message
              }
              if(error.field == 'password'){
                this.passwordInput.serverErrVal = this.passwordInput.val
                this.passwordInput.serverErrMsg = error.message
              }
            });
          }
          this.$toast.error('Failed to create new admin.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })

      }

    }
  }
}
</script>
